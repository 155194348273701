import React, { useState, useContext, useCallback } from "react";
import Layout from "../../components/layout";
import * as styles from "./buero.module.scss";
import { graphql } from "gatsby";
import beuroPhoto from "../../images/buero/initial.jpg";
import Modal from "../../components/Modal";
import Seo from "../../components/seo";
import { CursorContext } from "../../context/CursorContext";
import PortableText from "react-portable-text";
import TextSemibold from "../../utils/text-semibold";
import TextMedium from "../../utils/text-medium";

export const query = graphql`
  query BueroQuery {
    allSanitySiteSettings {
      nodes {
        _rawLebenslaufAlice
        _rawLebenslaufEtienne
        bueroPictureHoverAlice {
          asset {
            _id
            id
            url
          }
        }
        bueroPicture {
          asset {
            _id
            id
            url
          }
        }
        bueroPictureHoverEtienne {
          asset {
            _id
            id
            url
          }
        }
      }
    }
  }
`;

const BueroPage = ({ data }) => {
  const [state, setstate] = useState([false, false]);
  const [hoverImage, setHoverImage] = useState(
    data.allSanitySiteSettings.nodes[0].bueroPicture.asset.url
  );
  const [, setCursor] = useContext(CursorContext);

  const toggleCursor = useCallback((enabled) => {
    setCursor(() => ({ hover: enabled }));
  });

  return (
    <Modal one>
      <Seo title="Buero" />
      <div className={styles.ThumbContainer}>
        <img alt="Teambild" className={styles.Thumb} src={hoverImage}></img>
      </div>
      <div
        onClick={() => {
          setstate([false, true]);
        }}
        onMouseEnter={() => {
          setHoverImage(
            data.allSanitySiteSettings.nodes[0].bueroPictureHoverAlice.asset.url
          );
          toggleCursor(true);
        }}
        onMouseLeave={() => {
          setHoverImage(
            data.allSanitySiteSettings.nodes[0].bueroPicture.asset.url
          );
          toggleCursor(false);
        }}
        className={`${styles.TriggerZoneLeft}`}
      ></div>
      <div
        onClick={() => {
          setstate([true, false]);
        }}
        onMouseEnter={() => {
          setHoverImage(
            data.allSanitySiteSettings.nodes[0].bueroPictureHoverEtienne.asset
              .url
          );
          toggleCursor(true);
        }}
        onMouseLeave={() => {
          setHoverImage(
            data.allSanitySiteSettings.nodes[0].bueroPicture.asset.url
          );
          toggleCursor(false);
        }}
        className={`${styles.TriggerZoneRight}`}
      ></div>
      <div
        className={`${styles.InfoZoneLeft} ${state[1] ? styles.Visible : 0}`}
      >
        <PortableText
          content={data.allSanitySiteSettings.nodes[0]._rawLebenslaufAlice}
          serializers={{
            bigtext: BigText,
            semibold: TextSemibold,
            medium: TextMedium,
          }}
        />
      </div>
      <div
        className={`${styles.InfoZoneRight} ${state[0] ? styles.Visible : 0}`}
      >
        <PortableText
          content={data.allSanitySiteSettings.nodes[0]._rawLebenslaufEtienne}
          serializers={{
            bigtext: BigText,
            semibold: TextSemibold,
            medium: TextMedium,
          }}
        />
      </div>
    </Modal>
  );
};

const BigText = ({ children }) => <p className={styles.BigText}>{children}</p>;

BueroPage.Layout = Layout;

export default BueroPage;
